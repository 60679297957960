<div>
  <h1>{{ album.name }}</h1>
</div>
<mat-card>
  <mat-card-content fxLayout="row wrap" fxLayout.xs="column">
    <div fxflex>
      <img
        src="{{ album.images[1].url }}"
        alt="album cover"
        [style.margin]="'10px'"
        [style.max-width]="'90%'"
      />
    </div>
    <div fxFlex>
      <div [style.min-width]="'300px'" [style.margin]="'10px'">
        <strong>Record Label: </strong> {{ album.label }}<br />
        <strong>Release Date: </strong> {{ album.release_date }}<br /><br />
        <strong>Tracks:</strong> {{ album.total_tracks }}<br />
        <strong>Popularity: </strong>{{ album.popularity }}<br /><br />

        <mat-chip-list aria-label="Artist selection">
          <a [routerLink]="['/artist']">
            <mat-chip *ngFor="let artist of album.artists">{{
              artist.name
            }}</mat-chip></a
          >
        </mat-chip-list>
        <br />

        <div *ngFor="let copy of album.copyrights">
          {{ copy.text }}
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br /><br />
<h2>Track Listing</h2>

<mat-list>
  <!-- Single "Responsive" Material List Item-->
  <mat-list-item *ngFor="let item of album.tracks.items">
    <mat-icon mat-list-icon>queue_music</mat-icon>
    <div mat-line>
      <mat-card>
        <!-- NOTE 3.34 was determined by using (duration_ms / 60000).toFixed(2) -->
        <mat-card-content>
          {{ item.track_number }} {{ item.name }} 4 -
          {{ (item.duration_ms / 60000).toFixed(2) }}
        </mat-card-content>
      </mat-card>
    </div>
  </mat-list-item>
  <!-- -->
</mat-list>
